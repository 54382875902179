import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import {RIGHTS} from '../../../constants/Rights';
import {OrderDetailLine} from '../../../constants/propTypesDefinitions';
import {Button, Form} from 'antd';
import {Trans, t} from '@lingui/macro';
import FormModal from '../../fetch/FormModal';
import Date from '../../general/Date';
import OrderAvailabilityHistory from './OrderAvailabilityHistory';
import { formatProductName } from '../../../lib/formatting';
import generalFailedPC from '../../fetch/generalFailedPC';
import { formItemLayout, tailFormItemLayout } from '../../../constants/form';
import DatePicker from '../../general/DatePicker';
import { ORDER_LINE_STATE } from '../../../constants/orderStates';
const FormItem = Form.Item;

/**
 * @dusan
 */

class OrderAvailableAt extends React.PureComponent {
    static propTypes = {
        orderDetailsLine: OrderDetailLine.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {orderDetailsLine, onChange} = this.props;
        const isEditable = (orderDetailsLine.id_status != ORDER_LINE_STATE.FINISHED);
        return <div className="text-right">
            <div className="d-flex align-items-center justify-content-end">
                <Date dateString={orderDetailsLine.available_at}/>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    { isEditable ? 
                        <FormModal
                            openNode={
                                <Button icon="edit" size="small" className="ml-2"/> 
                            }
                            values={{orderDetailsLine, onChange}}
                            title={
                                <span>
                                    <Trans>Upraviť dodací termín</Trans>
                                    {' '}
                                    {formatProductName(orderDetailsLine.designation, orderDetailsLine.manufacturer)}
                                </span>
                            }
                            Form={EditOrderAvailableAtFormWrapped}
                            Response={null}
                            Failed={generalFailedPC(t`Nepodarilo upraviť dodací termín.`)}
                        /> :
                        null
                    }
                </RightsWrapper>
            </div>
            <OrderAvailabilityHistory history={orderDetailsLine.availability_history}/>
        </div>;
    }
}

export default OrderAvailableAt;

class EditOrderAvailableAtForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        values: PropTypes.shape({
            orderDetailsLine: OrderDetailLine.isRequired,
            onChange: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleSubmit = (e) => {
        const {values, onClose} = this.props;
        const {onChange} = values;
        
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err && onChange != null) {
                this.setState({loading: true})
                onChange(formValues.available_at);
                this.setState({loading: false});
                onClose();
            }
        });
    };

    render() {
        const {onClose, values} = this.props;
        const {orderDetailsLine} = values;
        const {getFieldDecorator} = this.props.form;
        const {loading} = this.state;

        return <Form onSubmit={this.handleSubmit}>
            <FormItem 
                {...formItemLayout}
                label={<Trans>Dátum dodania</Trans>}
            >
                {getFieldDecorator('available_at', {initialValue: orderDetailsLine.available_at})(
                    <DatePicker/>
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" onClick={this.handleSubmit} loading={loading}>
                    <Trans>Uložiť</Trans>
                </Button>
            </FormItem>
        </Form>;
    }

}

const EditOrderAvailableAtFormWrapped = Form.create()(EditOrderAvailableAtForm);