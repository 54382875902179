import React from 'react';
import PropTypes from 'prop-types';

import Helmet from '../project/Helmet';
import withDataHOC from '../dataProvider/withDataHOC';
import OrderLinesParams from './orderLines/OrderLinesParams';
import OrderLinesTable from './orderLines/OrderLinesTable';
import {GLOBAL_DATA} from '../../constants/globalData';
import {OrderDetailLine} from '../../constants/propTypesDefinitions';
import TableWithFiltersLayout from '../project/TableWithFiltersLayout';
import {Trans, t} from '@lingui/macro';
import { QUERY_PARAMS } from '../../constants/navigation';

/**
 * @dusan
 */

class OrderLinesPage extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.ORDER_LINES]: PropTypes.arrayOf(OrderDetailLine.isRequired).isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
    };


    render() {
        const {[GLOBAL_DATA.ORDER_LINES]: orderLines, [GLOBAL_DATA.RELOAD_DATA]: reload} = this.props;
        return <React.Fragment>
            <Helmet
                title={t`Riadky objednávok`}
            />
            <TableWithFiltersLayout
                title={<Trans>Riadky objednávok</Trans>}
                params={<OrderLinesParams/>}
                table={<OrderLinesTable
                    orderLines={orderLines}
                    reload={() => reload([GLOBAL_DATA.ORDER_LINES])}
                />}
                scrollParam={QUERY_PARAMS.ORDER_LINES_SCROLL}
            />
        </React.Fragment>;
    }
}

export default withDataHOC([GLOBAL_DATA.ORDER_LINES, GLOBAL_DATA.RELOAD_DATA])(OrderLinesPage);