import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';

/**
 * @fero
 */

class ManufacturersSelect extends React.PureComponent {
    static propTypes = {
        allowClear: PropTypes.bool,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number.isRequired)]),
        onChange: PropTypes.func.isRequired,
    };

    static defaultProps = {
        allowClear: false,
    };

    componentDidMount() {
        const manufacturers = this.props[GLOBAL_DATA.MANUFACTURERS];
        const {onChange, value, allowClear } = this.props;
        const firstValue = manufacturers.length > 0 ? manufacturers[0].id : null;
        if(value == null) {
            if (!allowClear) {
                onChange(firstValue);
            }
        }
    }

    render() {
        const {[GLOBAL_DATA.MANUFACTURERS]: manufacturers, ...props } = this.props;
        return <Select
            {...props}
            options={manufacturers.map(manufacturer => {
                return {
                    label: manufacturer.name,
                    value: manufacturer.id,
                }
            })}
        />;
    }
}

export default withDataHOC([GLOBAL_DATA.MANUFACTURERS])(ManufacturersSelect);