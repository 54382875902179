import React from 'react';
/**
 * @fero
 */

function Tile({children, className}) {
    return <div className={`tile-wrapper ${className}`}>
        {children}
    </div>;
}

export default Tile;