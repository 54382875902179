import React from 'react';
import PropTypes from 'prop-types';
import { getQueryParamNumber, navigateToParametrized } from '../../lib/url';
import locationHOC from '../locationProvider/locationHOC';


/**
 * @dusan
 */

class PersistScroll extends React.PureComponent {
	static propTypes = {
        scrollParam: PropTypes.string,
    };

    static defaultProps = {
        className: ""
    };

    componentDidUpdate(prevProps) {
        const {location, scrollParam} = this.props;
        const {location: prevLocation} = prevProps;
        if(this.scrollDiv != null && scrollParam != null)
        {
            const scrollTo = getQueryParamNumber(location, scrollParam);
            const prevScrollTo = getQueryParamNumber(prevLocation, scrollParam);

            if(scrollTo != prevScrollTo)
                this.scrollDiv.scrollTop = scrollTo;
        }
    }

    componentWillUnmount() {
        const {scrollParam} = this.props;
        if(this.scrollDiv != null && scrollParam != null)
        {
            const newPos = Math.round(this.scrollDiv.scrollTop);
            const location = window.location;

            navigateToParametrized(location, null, {
                [scrollParam] : newPos,
            }); 
        }
    }

    render() {
    	const {children, className} = this.props;
        return <div
            ref={node => this.scrollDiv = node}
            className={'overflow-auto ' + className}
        >
            {children}
        </div>;
    }

}

export default locationHOC(PersistScroll);