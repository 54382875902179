import React from 'react';
import PropTypes from 'prop-types';
import {rangeDateToString, stringToRangeDate} from '../../lib/date';
import {Trans, t} from '@lingui/macro';
import InputText from './InputText';

/**
 * @fero
 */

class DateRangePicker extends React.PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string,
    };

    createOnChangeFromDate = (to) => (from) => {
        this.change(from, to);
    };

    createOnChangeToDate = (from) => (to) => {
        this.change(from, to);
    };

    change = (from, to) => {
        const {onChange} = this.props;
        onChange(rangeDateToString(from, to));
    };

    render() {
        const {value} = this.props;
        const rangeDate = stringToRangeDate(value);
        const from = rangeDate[0];
        const to = rangeDate[1];

        return <div className="d-flex flex-wrap align-items-center full-size-width">
            <div className="d-flex align-items-center">
                <span className="mx-1"><Trans>od</Trans></span>
                <InputText
                    type="date"
                    className="date-range-picker-part"
                    onChange={this.createOnChangeFromDate(to)}
                    placeholder={'neuvedené'}
                    value={from}
                    max={to}
                />
            </div>
            <div className="d-flex align-items-center">
                <span className="mx-1"><Trans>do</Trans></span>
                <InputText
                    type="date"
                    className="date-range-picker-part"
                    onChange={this.createOnChangeToDate(from)}
                    placeholder={'neuvedené'}
                    value={to}
                    min={from}
                />
            </div>
        </div>;
    }

}

export default DateRangePicker;