import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../general/Tooltip';
import {Trans, t} from '@lingui/macro';
import Date from '../../general/Date';
import { AvailabilityHistoryItem } from '../../../constants/propTypesDefinitions';
import DateTime from '../../general/DateTime';

/**
 * @dusan
 */

class OrderAvailabilityHistory extends React.PureComponent {
    static propTypes = {
        history: PropTypes.arrayOf(AvailabilityHistoryItem),
    };

    constructor(props) {
        super(props);
        this.state = {
            showAll: false,
        };
    }

    toggleShowAll = () => {
        const {showAll} = this.state;
        this.setState({showAll: !showAll});
    };

    render() {
        const {history} = this.props;
        const {showAll} = this.state;
        if(history != null && Array.isArray(history) && history.length > 0) 
        {
            const historyChecked = showAll ? history : history.slice(0, 3);
            return <div className="table-subdata mt-2" style={{display: 'block'}}>
                <div><Trans>História:</Trans></div>
                { historyChecked.map((rec, idx) => {
                    return <Tooltip key={idx} title={
                            <span>
                                <Trans>Zmenené dňa:</Trans>
                                {' '}
                                <DateTime timeString={rec.changed_at}/>
                                {' (' + rec.who + ')'}
                            </span>
                        }
                    >
                        <div><Date dateString={rec.available_at}/></div>
                    </Tooltip>;
                })}
                { history.length > 3 ? 
                    <div onClick={this.toggleShowAll} className="text-dark">
                        { showAll ? <Trans>menej ...</Trans> : <Trans>viac ...</Trans>}
                    </div> 
                    : null
                }
            </div>;
        }
        else 
        {
            // nothing to show
            return null;
        }
    }
}

export default OrderAvailabilityHistory;