import React from 'react';
import locationHOC from '../../locationProvider/locationHOC';
import InputText from '../../general/InputText';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import ColumnFilterLayout from '../../project/ColumnFilterLayout';
import DateRangePicker from '../../general/DateRangePicker';
import {getQueryParam, createSetQueryParamValue, navigateToParametrized, getQueryParamNumber} from '../../../lib/url';
import {QUERY_PARAMS, ROUTES} from '../../../constants/navigation';
import {Location} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {Button, Radio} from 'antd';
import {Trans, t} from '@lingui/macro';
import OrderLineStatesSelect from '../../project/OrderLineStatesSelect';
import ManufacturersSelect from '../../project/ManufacturersSelect';
import CustomersSelect from '../../project/CustomersSelect';
import { getInputOnChangeEventValue } from '../../../lib/project';
import { TRADE } from '../../../constants/trade';
import { currentDate, dateAdd } from '../../../lib/date';

/**
 * @dusan
 */

const ORDER_LINE_ACTIVE = 10;

class OrderLinesParams extends React.PureComponent {
    static propTypes = {
        location: Location.isRequired,
    };

    clearAllParams = () => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.ORDER_TRADE]: undefined,
            [QUERY_PARAMS.ORDER_LINES_ORDER_REFERENCE]: undefined,
            [QUERY_PARAMS.ORDER_LINES_CUSTOMER_REFERENCE]: undefined,
            [QUERY_PARAMS.ORDER_LINES_ID_STATUS]: undefined,
            [QUERY_PARAMS.ORDER_LINES_DESIGNATION]: undefined,
            [QUERY_PARAMS.ORDER_LINES_SENT_AT]: undefined,
            [QUERY_PARAMS.ORDER_LINES_REQUESTED_AT]: undefined,
            [QUERY_PARAMS.ORDER_LINES_AVAILABLE_AT]: undefined,
            [QUERY_PARAMS.ORDER_LINES_ID_MANUFACTURER]: undefined,
            [QUERY_PARAMS.ORDER_LINES_ID_CUSTOMER]: undefined,
            [QUERY_PARAMS.ORDER_LINES_ORDER_BY]: undefined,
            [QUERY_PARAMS.ORDER_LINES_SCROLL]: undefined,
            [QUERY_PARAMS.ORDER_LINES_LIMIT]: process.env.DEFAULT_TABLE_MIN_RESULT_LIMIT,
        });
    };

    render() {
        const {location} = this.props;
        const trade = getQueryParam(location, QUERY_PARAMS.ORDER_TRADE);
        const orderRef = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_ORDER_REFERENCE);
        const customerReference = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_CUSTOMER_REFERENCE);
        const statusId = getQueryParamNumber(location, QUERY_PARAMS.ORDER_LINES_ID_STATUS);
        const designation = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_DESIGNATION);
        const createAtDateString = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_SENT_AT);
        const requestedAt = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_REQUESTED_AT);
        const availableAt = getQueryParam(location, QUERY_PARAMS.ORDER_LINES_AVAILABLE_AT);
        const customerId = getQueryParamNumber(location, QUERY_PARAMS.ORDER_LINES_ID_CUSTOMER);
        const manufacturerId = getQueryParamNumber(location, QUERY_PARAMS.ORDER_LINES_ID_MANUFACTURER);
        const hasFilter = orderRef != null || customerReference != null || statusId != null || createAtDateString != null 
        || designation != null || customerId != null || manufacturerId != null;
        const dt = new Date();
        const dayOfWeek = (dt.getDay() - 1 + 7) % 7;
        const today = currentDate();
        const thisWeekCond = 'BETWEEN ' + dateAdd(today, -dayOfWeek) + ' AND ' + dateAdd(today, 6-dayOfWeek);
        const nextWeekCond = 'BETWEEN ' + dateAdd(today, 7-dayOfWeek) + ' AND ' + dateAdd(today, 15-dayOfWeek);
        return <ColumnFilterLayout
            filters={[
                [
                    {
                        label: <Trans>Zobraziť: </Trans>,
                        selector: <Radio.Group
                            onChange={() => {
                                navigateToParametrized(location, ROUTES.ORDERS, {})
                            }}
                            value={"lines"}
                        >
                            <Radio.Button value={"list"}><Trans>Zoznam</Trans></Radio.Button>
                            <Radio.Button value={"lines"}><Trans>Riadky objednávok</Trans></Radio.Button>
                        </Radio.Group>
                    },
                    {
                        label: <Trans>Typ: </Trans>,
                        selector: <Radio.Group
                            onChange={(e) => {
                                const value = getInputOnChangeEventValue(e);
                                createSetQueryParamValue(location, QUERY_PARAMS.ORDER_TRADE)(value);
                            }}
                            value={trade}
                        >
                            <Radio.Button value={TRADE.SELL}><Trans>Prijaté</Trans></Radio.Button>
                            <Radio.Button value={TRADE.BUY}><Trans>Odoslané</Trans></Radio.Button>
                            <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                        </Radio.Group>,
                        rightsFrom: RIGHTS.MARKETING
                    },
                    {
                        label: <Trans>Číslo objednávky: </Trans>,
                        selector: <InputText
                            value={orderRef}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_ORDER_REFERENCE)}
                        />,
                    },
                    {
                        label: [
                            <RightsWrapper key="refCustomer" to={RIGHTS.DISTRIBUTOR}>
                                <Trans>Vaše číslo objednávky: </Trans>
                            </RightsWrapper>,
                            <RightsWrapper key="refDealer" from={RIGHTS.MARKETING}>
                                <Trans>Číslo obj. organizácie: </Trans>
                            </RightsWrapper>
                        ],
                        selector: <InputText
                            value={customerReference}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_CUSTOMER_REFERENCE)}
                        />,
                    },
                    {
                        label: <Trans>Stav: </Trans>,
                        selector: <OrderLineStatesSelect
                            className="full-size-width"
                            value={statusId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_ID_STATUS)}
                            allowClear={true}
                        />,
                    },
                ],
                [
                    {
                        label: <Trans>Organizácia:</Trans>,
                        selector: <CustomersSelect
                            value={customerId}
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_ID_CUSTOMER)}
                            allowClear={true}
                        />,
                        rightsFrom: RIGHTS.WORKER
                    },
                    {
                        label: <Trans>Hľadaj produkt:</Trans>,
                        selector: <InputText
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_DESIGNATION)}
                            value={designation}
                        />,
                    },
                    {
                        label: <Trans>Výrobca:</Trans>,
                        selector: <ManufacturersSelect
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_ID_MANUFACTURER)}
                            value={manufacturerId}
                            allowClear={true}
                        />,
                    }
                ],
                [
                    
                    {
                        label: <Trans>Odoslané:</Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_SENT_AT)}
                            value={createAtDateString}
                        />,
                    },
                    {
                        label: <Trans>Dátum potreby:</Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_REQUESTED_AT)}
                            value={requestedAt}
                        />,
                    },
                    {
                        selector: <div className="full-size-width text-right">
                            <Radio.Group
                                onChange={(e) => {
                                    const value = getInputOnChangeEventValue(e);
                                    navigateToParametrized(location, null, {
                                        [QUERY_PARAMS.ORDER_LINES_REQUESTED_AT]: value,
                                        [QUERY_PARAMS.ORDER_LINES_ID_STATUS]: value != null ? ORDER_LINE_ACTIVE : null
                                    });
                                }}
                                value={requestedAt}
                            >
                                <Radio.Button value={thisWeekCond}><Trans>Tento týždeň</Trans></Radio.Button>
                                <Radio.Button value={nextWeekCond}><Trans>Budúci týždeň</Trans></Radio.Button>
                                <Radio.Button value={undefined}><Trans>Všetky</Trans></Radio.Button>
                            </Radio.Group>
                        </div>
                    },
                    {
                        label: <Trans>Dodací termín:</Trans>,
                        selector: <DateRangePicker
                            onChange={createSetQueryParamValue(location, QUERY_PARAMS.ORDER_LINES_AVAILABLE_AT)}
                            value={availableAt}
                        />,
                    },
                    {
                        label: null,
                        selector: <Button
                            type={hasFilter ? "danger" : null}
                            onClick={this.clearAllParams}
                            className="full-size-width"
                        >
                            <Trans>Vyčistiť všetky filtre</Trans>
                        </Button>,
                    }
                ],
            ]}
        />;
    }

}

export default locationHOC(OrderLinesParams);