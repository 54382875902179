import React from 'react';
import PropTypes from 'prop-types';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class TileAttribute extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        value: PropTypes.node,
        nullLabel: PropTypes.node,
        separator: PropTypes.string,
        className: PropTypes.string,
        labelClassName: PropTypes.string,
    };

    static defaultProps = {
        nullLabel: "-",
        separator: ":",
        className: "",
        labelClassName: "",
    };

    render() {
        const {title, value, nullLabel, className, separator, labelClassName} = this.props;
        return <div className={"my-1 px-2 d-flex align-items-center " + className}>
            <div className={'mr-2 text-secondary text-right ' + labelClassName}>
                {title}
                {separator}
            </div>
            {value != null ? value : nullLabel}
        </div>;
    }

}

export default TileAttribute;