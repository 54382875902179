import React from 'react';
import PropTypes from 'prop-types';
import withSessionHOC from '../sessionProvider/withSessionHOC';
import { SESSION_ATTRIBUTES } from '../sessionProvider/SessionProvider';
import PersistScroll from '../general/PersistScroll';


/**
 * @fero
 */

class TableWithFiltersLayout extends React.PureComponent {
    static propTypes = {
        title: PropTypes.node.isRequired,
        params: PropTypes.node,
        table: PropTypes.node,
        summary: PropTypes.node,
        scrollParam: PropTypes.string,
    };

    render() {
        const {title, params, table, summary, scrollParam, [SESSION_ATTRIBUTES.IS_MOBILE]: isMobile} = this.props;
        return isMobile ? 
        <div className="full-size-height d-flex flex-column">
            <div className="flex-item-static">
                <h2 className="text-center">{title}</h2>
            </div>
            <div className="overflow-y-scroll">
                {params}
                {table}
            </div>
        </div> :
        <div className="px-3 full-size-height d-flex flex-column">
            <div className="py-3 flex-item-static">
                {params}
            </div>
            <PersistScroll scrollParam={scrollParam} className="flex-item-dynamic-1">
                {table}
            </PersistScroll>
            <div className="flex-item-static">
                {summary}
            </div>
        </div>;
    }
}

export default withSessionHOC([SESSION_ATTRIBUTES.IS_MOBILE])(TableWithFiltersLayout);