import React from 'react';
import PropTypes from 'prop-types';
import {OrderDetailLine} from '../../../constants/propTypesDefinitions';
import Date from '../../general/Date';
import DatePicker from '../../general/DatePicker';
import { ORDER_LINE_STATE } from '../../../constants/orderStates';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import { RIGHTS } from '../../../constants/Rights';
import { currentDate } from '../../../lib/date';

/**
 * @dusan
 */

class OrderRequestedAt extends React.PureComponent {
    static propTypes = {
        orderDetailsLine: OrderDetailLine.isRequired,
        onChange: PropTypes.func.isRequired,
    };

    render() {
        const {orderDetailsLine, onChange} = this.props;
        const isEditable = (orderDetailsLine.id_status != ORDER_LINE_STATE.FINISHED);
        return <RightsWrapper from={RIGHTS.WORKER}>
            { isEditable ?
                <DatePicker
                    size="small"
                    value={orderDetailsLine.requested_at}
                    min={currentDate()}
                    placeholder="ASAP"
                    onChange={onChange}
                />
                : <Date dateString={orderDetailsLine.requested_at} nullOption="ASAP"/>
            }
        </RightsWrapper>;
    }
}

export default OrderRequestedAt;