import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../../dataProvider/withDataHOC';
import {merge} from '../../../lib/object';
import {isNumberAndGreaterOrEqual, isNumberAndGreaterThan} from '../../../lib/number';
import {createFetchOrderEditItem} from '../../../backend/apiCalls';
import {isOrderLineFinished} from '../../../lib/project';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {OrderDetailLine, Customer, OrderDetail} from '../../../constants/propTypesDefinitions';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import { ORDER_LINE_STATE } from '../../../constants/orderStates';
import { notification } from 'antd';

/**
 * @dusan
 */


const orderItem = (Component) => {
    return class extends React.PureComponent {
        static propTypes = {
            reload: PropTypes.func.isRequired,
            data: OrderDetailLine.isRequired,
            isSelling: PropTypes.bool,
            isSent: PropTypes.bool,
            finalOrder: OrderDetail.isRequired,
            [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
        };

        editItemOrder = (queryParams) => {
            const {data, reload} = this.props;
            const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
            fetchHandler(createFetchOrderEditItem(),
                merge(
                    {
                        id_order: data.id_order,
                        id_item: data.id_item,
                    },
                    queryParams
                ),
                null,
                reload,
                (error) => {
                    notification['error']({ 
                        message: error.message,
                        duration: 10,
                    });
                }
            );
        };

        onAvailableAtChange = (availableAt) => {
            this.editItemOrder({available_at: availableAt});
        };

        onRequestedAtChange = (requestedAt) => {
            this.editItemOrder({requested_at: requestedAt});
        };

        onCustomerNoticeChange = (newNotice) => {
            this.editItemOrder({customer_notice: newNotice});
        };

        onPriceLevelChange = (newPrice, newPriceLevelId) => {
            if(isNumberAndGreaterOrEqual(newPrice, 0))
                this.editItemOrder({package_price: newPrice, id_price_level: newPriceLevelId});
            else
                this.editItemOrder({id_price_level: newPriceLevelId});
        };

        onStockChange = (newStockId) => {
            this.editItemOrder({id_stock: newStockId});
        };

        onDealerNoticeChange = (newNotice) => {
            this.editItemOrder({dealer_notice: newNotice});
        };

        onPackageTypeChange = (newPack) => {
            this.editItemOrder({id_package_type: newPack});
        };

        onPriceChange = (newPrice) => {
            const {reload} = this.props;
            if(isNumberAndGreaterOrEqual(newPrice, 0))
                this.editItemOrder({package_price: newPrice});
            else
                reload();
        };

        onQuantityChange = (newQuantity) => {
            const {reload} = this.props;
            if(isNumberAndGreaterThan(newQuantity, 0))
                this.editItemOrder({package_quantity: newQuantity});
            else
                reload();
        };

        onSequenceChange = (newSequence) => {
            const {reload} = this.props;
            if(isNumberAndGreaterThan(newSequence, 0))
                this.editItemOrder({sequence: newSequence});
            else
                reload();
        };

        onAtomicChange = (newAtomic) => {
            this.editItemOrder({is_atomic: newAtomic});
        };

        onAddressChange = (newAddressId) => {
            this.editItemOrder({id_delivery_address: newAddressId});
        };

        render() {
            const {data, isSelling, isSent, [SESSION_ATTRIBUTES.RIGHTS]: rights, ...rest} = this.props;
            const quantityBalance = Number(data.quantity_opened) + Number(data.quantity_reserved) - Number(data.quantity_on_stock) - Number(data.quantity_ordered);    
            const isNotCovered = (data.id_status == ORDER_LINE_STATE.NOT_COVERED);
            const missingQuantity = isNotCovered ?
                Math.min(Math.max(quantityBalance, 0), Number(data.quantity_opened)): null;

            const isFinished = (data != null && isOrderLineFinished(data));
            return <Component
                onAvailableAtChange={this.onAvailableAtChange}
                onRequestedAtChange={this.onRequestedAtChange}
                onCustomerNoticeChange={this.onCustomerNoticeChange}
                onDealerNoticeChange={this.onDealerNoticeChange}
                onPriceChange={this.onPriceChange}
                onQuantityChange={this.onQuantityChange}
                onSequenceChange={this.onSequenceChange}
                onPackageTypeChange={this.onPackageTypeChange}
                onPriceLevelChange={this.onPriceLevelChange}
                onStockChange={this.onStockChange}
                onAtomicChange={this.onAtomicChange}
                onAddressChange={this.onAddressChange}
                isNotCovered={isNotCovered}
                missingQuantity={missingQuantity}
                isFinished={isFinished}
                isSent={isSent}
                isSelling={isSelling}
                data={data}
                {...rest}
            />;
        }
    }
};

const orderItemHOC = (Component) =>  withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(orderItem(Component));

export default orderItemHOC;
